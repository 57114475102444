<template>
    <v-container fluid>
        <ApolloQuery
            :query="require('@/graphql/project/allProjects.gql')"
            :variables="query_vars"
        >
            <template v-slot="{ result: { loading, error, data } }">
                <v-progress-linear indeterminate v-if="loading" />
                <v-data-iterator
                    v-if="data && !loading"
                    :items="applySearchFilter(data.allProjects)"
                    :search="keywords"
                    :items-per-page.sync="itemsPerPage"
                    :footer-props="{ itemsPerPageOptions }"
                >
                    <template v-slot:default="props">
                        <v-row>
                            <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="6"
                                md="4"
                                lg="3"
                            >
                                <project-card
                                    :project.sync="item"
                                    @delete-clicked="promptForDelete"
                                    @edit-clicked="editProject"
                                />
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
                <v-alert type="error" v-else-if="error">
                    There was an error loading data. The server said...<br />
                    <strong>{{ error }}</strong>
                </v-alert>
            </template>
        </ApolloQuery>
        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="320"
            v-if="selectedProject.name != ''"
        >
            <v-card>
                <v-card-title class="headline">Delete Project?</v-card-title>
                <v-card-text>
                    <p>
                        You are about to delete the project
                        <strong>{{ selectedProject.name }}</strong
                        >. This will remove the project record as well as any
                        associations with users and projects. This cannot be
                        undone. Are you sure you want to delete this project?
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="deleteDialog = false"
                        >{{ $t('cancel') }}</v-btn
                    >
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="deleteProject(selectedProject)"
                        >{{ $t('delete') }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import ProjectCard from '@/views/projects/ProjectCard'
import moment from 'moment'

export default {
    name: 'ProjectsGrid',
    components: {
        'project-card': ProjectCard,
    },
    props: {
        keywords: {
            type: String,
            required: true,
        },
        reload: {
            type: Number,
            default: 0,
        },
        client_id: {
            type: String,
        },
        projectFilterStartDate: {
            type: String,
        },
        projectFilterEndDate: {
            type: String,
        },
    },
    data: () => ({
        itemsPerPageOptions: [4, 8, 12],
        itemsPerPage: 8,
        deleteDialog: false,
        selectedProject: {},
        rando: 0,
    }),
    watch: {
        reload: function(val) {
            this.rando = val
        },
    },
    computed: {
        query_vars: function() {
            if (this.$store.getters.userForPortalAccess.user_type === 'admin') {
                return { garbage: this.rando }
            } else {
                return { client_id: this.client_id, garbage: this.rando }
            }
        },
    },
    methods: {
        formatProjectDateToTimeNum(date) {
            return moment(date, 'YYYY-MM-DD HH:mm:ss').valueOf()
        },
        formatInputDateToTimeNum(date) {
            return moment(date, 'YYYY-MM-DD').valueOf()
        },
        filterClientId(projectItem) {
            if (this.client_id && projectItem.client) {
                return this.client_id === projectItem.client.id
            }
            return true
        },
        filterStartDate(projectItem) {
            if (this.projectFilterStartDate && projectItem.created_at) {
                return (
                    this.formatProjectDateToTimeNum(projectItem.created_at) >=
                    this.formatInputDateToTimeNum(this.projectFilterStartDate)
                )
            }
            return true
        },
        filterEndDate(projectItem) {
            if (this.projectFilterEndDate && projectItem.updated_at) {
                return (
                    this.formatProjectDateToTimeNum(projectItem.updated_at) <=
                    this.formatInputDateToTimeNum(this.projectFilterEndDate)
                )
            }
            return true
        },
        applySearchFilter(projects) {
            if (projects) {
                return projects
                    .filter(this.filterClientId)
                    .filter(this.filterStartDate)
                    .filter(this.filterEndDate)
            }
            return projects
        },
        promptForDelete(project) {
            this.selectedProject = project
            this.deleteDialog = true
        },
        deleteProject(project) {
            this.$apollo
                .mutate({
                    // Query
                    mutation: require('@/graphql/project/deleteProject.gql'),
                    // Parameters
                    variables: {
                        id: project.id,
                    },
                })
                .then(data => {
                    // report success
                    this.$emit('pool-form-closed', data)
                    this.rando++
                })
                .catch(error => {
                    // Error
                    console.error(error)
                    // update user....
                })
            this.deleteDialog = false
        },
        editProject(project) {
            this.$emit('edit-project', project)
        },
    },
}
</script>
