<template>
    <v-card>
        <v-list-item>
            <v-list-item-avatar color="grey">
                <v-icon dark>mdi-clipboard-list</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                {{ project.name }}
                <v-list-item-subtitle>
                    {{ project.client.name }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-card-text>
            <p>
                Project Owner: {{ project.owner.name }}<br />
                Project Manager: {{ project.manager.name }}<br />
                Variable Sets: {{ project.variable_sets.length }} <br />
            </p>
        </v-card-text>
        <v-card-actions>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="loadVariableSets">
                        <v-icon>mdi-format-align-bottom</v-icon>
                    </v-btn>
                </template>
                <span>Variable Sets</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="showContentPools">
                        <v-icon>mdi-database</v-icon>
                    </v-btn>
                </template>
                <span>Content Pools</span>
            </v-tooltip>
            <v-spacer />

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="editProject">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="promptForDelete">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <span>Delete {{ project.name }}</span>
            </v-tooltip>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'ProjectCard',
    props: {
        project: {
            type: Object,
            required: true,
        },
    },
    data: () => ({}),
    computed: {
        docCount: function() {
            return 5
        },
        userCount: function() {
            return 0
            //return this.project.users.length || 0;
        },
    },
    methods: {
        loadForm(project) {
            this.$emit('loadProjectForm', project)
        },
        loadVariableSets() {
            this.$router.push({ path: `/variables/${this.project.id}` })
        },
        promptForDelete() {
            this.$emit('delete-clicked', this.project)
        },
        editProject() {
            this.$emit('edit-clicked', this.project)
        },
        showContentPools() {
            this.$router.push({
                path: `/output/${this.project.client.id}/${this.project.id}`,
            })
        },
    },
}
</script>
