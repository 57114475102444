<template>
    <v-card>
        <v-card-title>
            <h3>Projects</h3>
            <v-spacer />
            <v-col v-if="isSuperAdmin">
                <ApolloQuery
                    :query="require('@/graphql/client/clientsDropdown.gql')"
                >
                    <template v-slot="{ result: { loading, error, data } }">
                        <v-autocomplete
                            v-if="data"
                            :items="data.allClients"
                            :loading="loading"
                            item-text="name"
                            item-value="id"
                            v-model="client_id"
                            label="Client"
                            clearable
                        />
                        <v-autocomplete v-else label="Loading Clients" />
                    </template>
                </ApolloQuery>
            </v-col>
            <v-col>
                <v-menu
                    v-model="showStartDatePicker"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="theStartPublishDateText"
                            label="Publish start date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="datepicker"
                            clearable
                            @click:clear="clearProjectStartDate"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="projectFilterStartDate"
                        @input="updateStartDate"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col>
                <v-menu
                    v-model="showEndDatePicker"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="theEndPublishDateText"
                            label="Publish end date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="datepicker"
                            clearable
                            @click:clear="clearProjectEndDate"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="projectFilterEndDate"
                        @input="updateEndDate"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col style="margin-bottom: 22px">
                <v-text-field
                    v-model="keywords"
                    append-icon="mdi-magnify"
                    label="Keyword"
                    clearable
                    single-line
                    hide-details
                />
            </v-col>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        color="primary"
                        @click="newProjectForm()"
                        fab
                        dark
                    >
                        <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>New Project</span>
            </v-tooltip>
        </v-card-title>
        <v-alert type="error" v-if="inValidProjectFilterDates">
            The project publish end date can not be earlier than the start date.
        </v-alert>
        <v-card-text>
            <projects-grid
                :keywords.sync="keywords"
                :client_id.sync="client_id"
                :projectFilterEndDate.sync="projectFilterEndDate"
                :projectFilterStartDate.sync="projectFilterStartDate"
                :reload.sync="reloadVar"
                @edit-project="editProject"
            />
        </v-card-text>
        <v-dialog v-model="projectFormDialog" persistent max-width="600">
            <project-form
                :project.sync="formProject"
                @project-form-saved="
                    closeProjectForm()
                    reloadGrid()
                "
                @project-form-closed="closeProjectForm()"
            />
        </v-dialog>
    </v-card>
</template>
<script>
import ProjectsGrid from '@/views/projects/ProjectsGrid'
import ProjectForm from '@/views/projects/ProjectForm'
import moment from 'moment'

export default {
    name: 'projects',
    components: {
        'projects-grid': ProjectsGrid,
        'project-form': ProjectForm,
    },
    props: ['client_id'],
    data: () => ({
        keywords: '',
        projectFormDialog: false,
        formProject: {},
        reloadVar: 0,
        showStartDatePicker: false,
        showEndDatePicker: false,
        projectFilterStartDate: null,
        projectFilterEndDate: null,
        inValidProjectFilterDates: false,
    }),
    computed: {
        theStartPublishDateText() {
            return this.projectFilterStartDate
                ? this.formatDate(this.projectFilterStartDate)
                : ''
        },
        theEndPublishDateText() {
            return this.projectFilterEndDate
                ? this.formatDate(this.projectFilterEndDate)
                : ''
        },
        isSuperAdmin() {
            return this.$store.getters.userForPortalAccess.user_type === 'admin'
        },
    },
    methods: {
        reloadGrid: function() {
            this.reloadVar++
        },
        newProjectForm: function() {
            this.formProject = {}
            this.projectFormDialog = true
        },
        closeProjectForm: function() {
            this.projectFormDialog = false
        },
        editProject: function(project) {
            this.formProject = project
            this.projectFormDialog = true
        },
        formatDate(date) {
            return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY')
        },
        updateStartDate(date) {
            this.projectFilterStartDate = date
            this.showStartDatePicker = false
            this.validateProjectFilterDates()
        },
        updateEndDate(date) {
            this.projectFilterEndDate = date
            this.showEndDatePicker = false
            this.validateProjectFilterDates()
        },
        clearProjectEndDate() {
            this.projectFilterEndDate = null
            this.validateProjectFilterDates()
        },
        clearProjectStartDate() {
            this.projectFilterStartDate = null
            this.validateProjectFilterDates()
        },
        formatInputDateToTimeNum(date) {
            return moment(date, 'YYYY-MM-DD').valueOf()
        },
        validateProjectFilterDates() {
            if (this.projectFilterStartDate && this.projectFilterEndDate) {
                this.inValidProjectFilterDates =
                    this.formatInputDateToTimeNum(this.projectFilterStartDate) >
                    this.formatInputDateToTimeNum(this.projectFilterEndDate)
            } else {
                this.inValidProjectFilterDates = false
            }
        },
    },
}
</script>
