var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form"},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Project Form")])]),_c('v-card-text',[(_vm.formLoading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('project_name'),"disabled":_vm.formLoading,"required":"","rules":[_vm.rules.required],"validate-on-blur":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ApolloQuery',{attrs:{"query":require('@/graphql/client/clientsDropdown.gql')},scopedSlots:_vm._u([{key:"default",fn:function({
                                    result: { loading, error, data },
                                }){return [(data)?_c('v-autocomplete',{attrs:{"items":data.allClients,"disabled":_vm.formLoading,"loading":loading,"item-text":"name","item-value":"id","validate-on-blur":"","rules":[_vm.rules.required],"label":"Client","required":""},model:{value:(_vm.client_id),callback:function ($$v) {_vm.client_id=$$v},expression:"client_id"}}):_c('v-autocomplete',{attrs:{"label":"Loading Clients"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ApolloQuery',{attrs:{"query":require('@/graphql/user/userDropdown.gql'),"variables":{
                                user_type: 'admin',
                            }},scopedSlots:_vm._u([{key:"default",fn:function({
                                    result: { loading, error, data },
                                }){return [(data)?_c('v-autocomplete',{attrs:{"items":data.allUsers,"disabled":_vm.formLoading,"loading":loading,"item-text":"name","item-value":"id","validate-on-blur":"","label":"WD Project Owner","required":"","rules":[_vm.rules.required]},model:{value:(_vm.owner_id),callback:function ($$v) {_vm.owner_id=$$v},expression:"owner_id"}}):_c('v-autocomplete',{attrs:{"label":"Loading Owners"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ApolloQuery',{attrs:{"query":require('@/graphql/user/userDropdown.gql'),"variables":{
                                client_id: _vm.formData.client.id,
                            }},scopedSlots:_vm._u([{key:"default",fn:function({
                                    result: { loading, error, data },
                                }){return [(data)?_c('v-autocomplete',{attrs:{"items":data.allUsers,"disabled":_vm.formLoading,"loading":loading,"item-text":"name","item-value":"id","validate-on-blur":"","rules":[_vm.rules.required],"label":"Client Project Manager","required":""},model:{value:(_vm.manager_id),callback:function ($$v) {_vm.manager_id=$$v},expression:"manager_id"}}):_c('v-autocomplete',{attrs:{"label":"Loading Managers"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"name":"description","label":"Project Description","validate-on-blur":"","rules":[_vm.rules.required],"hint":"Enter description here..."},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":"","disabled":_vm.formLoading},on:{"click":_vm.closeModal}},[_vm._v(_vm._s(_vm.$t('close')))]),(_vm.formData.id)?_c('v-btn',{attrs:{"color":"primary darken-1","text":"","loading":_vm.formLoading,"disabled":_vm.formLoading},on:{"click":function($event){return _vm.updateProject()}}},[_vm._v(_vm._s(_vm.$t('save')))]):_c('v-btn',{attrs:{"color":"primary darken-1","text":"","disabled":_vm.formLoading,"loading":_vm.formLoading},on:{"click":function($event){return _vm.createProject()}}},[_vm._v(_vm._s(_vm.$t('create')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }